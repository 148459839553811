// User Time Zone - https://yarnpkg.com/package/jstz
// Install at website root: yarn add jstz
import jstz from 'jstz';

$(document).ready(function() {

	// User Time Zone
	
	if (jstz) {
		$('#user_time_zone_guess').val(jstz.determine().name());
	}

	// Onboarding Purchase

	var $onboardingPurchaseProductId = $("#client_onboarding_purchase_product_id");
	$('#onboarding-btn-select-id-verify').click(function() {
		$onboardingPurchaseProductId.attr("value", "8");
		$("#onboarding-purchase-type").hide();
		var newQuantityOptions = {"One": "1"};
		var $onboardingQuantityOption = $("#client_onboarding_purchase_quantity");
		$onboardingQuantityOption.empty();
		$.each(newQuantityOptions, function(key,value) {
			$onboardingQuantityOption.append($("<option></option>")
				.attr("value", value).text(key));
		});
		$("#onboarding-display-charge-details").text('Identity Verification - $1.00');
		$("#onboarding-company-info").show();
	});
	$('#onboarding-btn-select-test').click(function() {
		$onboardingPurchaseProductId.attr("value", "1");
		$("#onboarding-purchase-type").hide();
		var newQuantityOptions = {"One": "1"};
		var $onboardingQuantityOption = $("#client_onboarding_purchase_quantity");
		$onboardingQuantityOption.empty();
		$.each(newQuantityOptions, function(key,value) {
			$onboardingQuantityOption.append($("<option></option>")
				.attr("value", value).text(key));
		});
		$("#onboarding-company-info-selector").show();
	});
	$('#onboarding-btn-select-hourly').click(function() {
		$onboardingPurchaseProductId.attr("value", "2");
		$("#onboarding-purchase-type").hide();
		$("#onboarding-quantity-selector").show();
	});
	$('#onboarding-btn-select-website').click(function() {
		$onboardingPurchaseProductId.attr("value", "3");
		$("#onboarding-purchase-type").hide();
		var newQuantityOptions = {"One": "1"};
		var $onboardingQuantityOption = $("#client_onboarding_purchase_quantity");
		$onboardingQuantityOption.empty();
		$.each(newQuantityOptions, function(key,value) {
			$onboardingQuantityOption.append($("<option></option>")
				.attr("value", value).text(key));
		});
		$("#onboarding-company-info-selector").show();
	});



	$('#onboarding-btn-quantity').click(function() {
		$("#onboarding-quantity-selector").hide();
		$("#onboarding-company-info-selector").show();
	});



	$('#onboarding-btn-select-info-yes').click(function() {
		$("#onboarding-company-info-selector").hide();
		$("#onboarding-company-info").show();
		if ($onboardingPurchaseProductId.val() == '1') {
			$("#onboarding-display-charge-details").text('Test Product - $1.00');
		} else if ($onboardingPurchaseProductId.val() == '3') {
			$("#onboarding-display-charge-details").text('Website Deposit - $1,000.00');
		} else {
			var $onboardingQuantityOption = $("#client_onboarding_purchase_quantity");
			$("#onboarding-display-charge-details").text($onboardingQuantityOption.find(":selected").text());
		}
	});
	$('#onboarding-btn-select-info-no').click(function() {
		$("#onboarding-company-info-selector").hide();
		if ($onboardingPurchaseProductId.val() == '1') {
			$("#onboarding-display-charge-details").text('Test Product - $1.00');
		} else if ($onboardingPurchaseProductId.val() == '3') {
			$("#onboarding-display-charge-details").text('Website Deposit - $1,000.00');
		} else {
			var $onboardingQuantityOption = $("#client_onboarding_purchase_quantity");
			$("#onboarding-display-charge-details").text($onboardingQuantityOption.find(":selected").text());
		}
		$("#onboarding-run-transaction").show();
	});



	$('#onboarding-btn-company-info').click(function() {
		$("#onboarding-company-info").hide();
		$("#onboarding-run-transaction").show();
	});



});
